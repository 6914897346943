    import { getBaseConfig } from '../../config';
 
export const getConfig = () => {
    const mBaseConfig = getBaseConfig();
    if (mBaseConfig) {
        return {
            ...mBaseConfig,            
        };
    }
};
 
export const graphConfig = () => {
    return {
      endpoint: process.env.REACT_APP_CMS_API_URL,
      domain: process.env.REACT_APP_CMS_API_URL,
      accessToken: `Bearer ${process.env.REACT_APP_CMS_API_TOKEN}`,
      googleApiKey: 'AIzaSyDe2Kk6i-VPLHmGq-0_RH7JYk1QlaxSYMsI',
      sitecoreApiKey: '7A3E0468-E733-4F81-AABE-2BC0E797E5E0',
    };
  };