import { combineEpics } from "redux-observable";
import { getSellingPricesEpic } from "./getSellingPricesEpic";
import { getSellingPricesWithTopCatalogEpic } from "./getSellingPricesWithTopCatalogEpic";
import { getDiscontinuedPricesEpic } from "./getDiscontinuedPricesEpic";
import { searchProductsEpic } from "./searchProductsEpic";
import { getUnSellingPricesEpic } from "./getUnSellingPricesEpic";
import { getPausedProductsEpic } from "./getPausedProductsEpic";
import { exportPricesEpic } from "./exportPricesEpic";
import { exportPricesV2Epic } from "./exportPricesV2Epic";
import { exportVariantsEpic } from "./exportVariantsEpic";
import { importVariantsEpic } from "./importVariantsEpic";
import { importPricesHistoryEpic } from "./importPricesHistoryEpic";
import { importPricesEpic, importAlarmProductsEpic } from "./importPricesEpic";
import { getFileImportPriceEpic } from "./getFileImportPriceEpic";
import { updatePriceEpic } from "./updatePriceEpic";
import { setActivePriceEpic } from "./setActivePriceEpic";
import { setDiscontinuedPriceEpic } from "./setDiscontinuedPriceEpic";
import { getActiveCustomersEpic } from "./getActiveCustomersEpic";
import { getOverrideDisplayEpic } from "./getOverrideDisplayEpic";
import { createPriceEpic } from "./createPriceEpic";
import { getPriceDetailEpic } from "./getPriceDetailEpic";
import { getProductSettingsEpic } from "./getProductSettingsEpic";
import { getVariantDetailEpic } from "./getVariantDetailEpic";
import { deletePriceEpic } from "./deletePriceEpic";
import { getPriceCustomerGroupsEpic } from "./getPriceCustomerGroupsEpic";
import { createCustomerGroupPriceEpic } from "./createCustomerGroupPriceEpic";
import { settingCustomProductEpic } from "./settingCustomProductEpic";
import { deleteCustomerGroupPriceEpic } from "./deleteCustomerGroupPriceEpic";
import { updateCustomerGroupPriceEpic } from "./updateCustomerGroupPriceEpic";
import { disablePriceForGroupEpic } from "./disablePriceForGroupEpic";
import { updatePromotionEpic } from "./updatePromotionEpic";
import { setProductVisibleEpic } from "./setProductVisibleEpic";
import { setOverrideDisplayEpic } from "./setOverrideDisplayEpic";
import { setProductPriorityEpic } from "./setProductPriorityEpic";
import { setColorVariantEpic } from "./setColorVariantEpic";
import { getProductsUploadHistoryEpic } from "./getProductsUploadHistoryEpic";
import { getAlarmProductsFileEpic } from "./getAlarmProductsFileEpic";
import { getDetailErrorEpic } from "./getDetailErrorEpic";

export const productsEpic = combineEpics(
    getSellingPricesEpic,
    getSellingPricesWithTopCatalogEpic,
    getDiscontinuedPricesEpic,
    searchProductsEpic,
    getUnSellingPricesEpic,
    getPausedProductsEpic,
    exportPricesEpic,
    exportPricesV2Epic,
    exportVariantsEpic,
    getFileImportPriceEpic,
    importVariantsEpic,
    importPricesHistoryEpic,
    importPricesEpic,
    updatePriceEpic,
    updatePromotionEpic,
    setActivePriceEpic,
    setDiscontinuedPriceEpic,
    getActiveCustomersEpic,
    getOverrideDisplayEpic,
    createPriceEpic,
    getPriceDetailEpic,
    getProductSettingsEpic,
    getVariantDetailEpic,
    deletePriceEpic,
    getPriceCustomerGroupsEpic,
    createCustomerGroupPriceEpic,
    deleteCustomerGroupPriceEpic,
    updateCustomerGroupPriceEpic,
    disablePriceForGroupEpic,
    setProductVisibleEpic,
    setOverrideDisplayEpic,
    setProductPriorityEpic,
    setColorVariantEpic,
    importAlarmProductsEpic,
    getProductsUploadHistoryEpic,
    getAlarmProductsFileEpic,
    getDetailErrorEpic,
    settingCustomProductEpic
);