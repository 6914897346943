import Customer from "../resource/customer";
import Order from "../resource/order";
import Product from "../resource/product";
import Sale from "../resource/sale";
import Notification from "../resource/notification";
import Invoice from "../resource/invoice";
import Flyer from "../resource/flyer";
import TempProduct from "../resource/tempProduct";
import OrderOnBehalf from "../resource/orderonbehalf";
import Mitsubishi from "../resource/mitsubishi";
// export const getApiController = (storeView = 'jp') => storeView ? `rest/V1` : 'rest/V1';
export const getApiController = (storeView = "jp") => (storeView ? `` : "");

export const SitecoreResource = {
  Customer,
  Order,
  Product,
  Sale,
  Notification,
  Invoice,
  TempProduct,
  Flyer,
  OrderOnBehalf,
  Mitsubishi
};
