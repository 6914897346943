import { IAction } from "../../../../shared/interfaces/common";
import { ISetActivePricePayload, ICreateCustomerGroupPricePayload, ICreatePricePayload, IGetPriceDetailPayload, IUpdatePromotionPayload, IUpdateCustomerGroupPricePayload, IUpdatePricePayload, IActiveCustomersPayload, IImportPricesHistoryPayload, IExportVariantsPayload, ISetColorVariantPayload, IGetFilePayload } from "../interfaces/product.types";
import { IExportPricePayload, IGetProductsPayload, IGetProductsUploadHistoryPayload, ISetOverrideDisplayPayload, ISetProductPriorityPayload, ISetProductVisiblePayload } from "./products.types";

// Products Action Types
export enum ProductActionTypes {
  GET_SELLING_PRICES = "[Products] GET_SELLING_PRICES",
  GET_SELLING_PRICES_SUCCESS = "[Products] GET_SELLING_PRICES_SUCCESS",
  GET_SELLING_PRICES_FAIL = "[Products] GET_SELLING_PRICES_FAIL",

  GET_SELLING_CATALOG_PRICES = "[Products] GET_SELLING_CATALOG_PRICES",
  GET_SELLING_CATALOG_PRICES_SUCCESS = "[Products] GET_SELLING_CATALOG_PRICES_SUCCESS",
  GET_SELLING_CATALOG_PRICES_FAIL = "[Products] GET_SELLING_CATALOG_PRICES_FAIL",

  GET_DISCONTINUED_PRICES = "[Products] GET_DISCONTINUED_PRICES",
  GET_DISCONTINUED_PRICES_SUCCESS = "[Products] GET_DISCONTINUED_PRICES_SUCCESS",
  GET_DISCONTINUED_PRICES_FAIL = "[Products] GET_DISCONTINUED_PRICES_FAIL",

  SEARCH_PRODUCTS = "[Products] SEARCH_PRODUCTS",
  SEARCH_PRODUCTS_SUCCESS = "[Products] SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAIL = "[Products] SEARCH_PRODUCTS_FAIL",

  GET_UNSELLING_PRICES = "[Products] GET_UNSELLING_PRICES",
  GET_UNSELLING_PRICES_SUCCESS = "[Products] GET_UNSELLING_PRICES_SUCCESS",
  GET_UNSELLING_PRICES_FAIL = "[Products] GET_UNSELLING_PRICES_FAIL",

  GET_PAUSED_PRODUCTS = "[Products] GET_PAUSED_PRODUCTS",
  GET_PAUSED_PRODUCTS_SUCCESS = "[Products] GET_PAUSED_PRODUCTS_SUCCESS",
  GET_PAUSED_PRODUCTS_FAIL = "[Products] GET_PAUSED_PRODUCTS_FAIL",
  
  EXPORT_PRICES = "[Products] EXPORT_PRICES",
  EXPORT_PRICES_SUCCESS = "[Products] EXPORT_PRICES_SUCCESS",
  EXPORT_PRICES_FAIL = "[Products] EXPORT_PRICES_FAIL",
  
  EXPORT_PRICES_V2 = "[Products] EXPORT_PRICES_V2",
  EXPORT_PRICES_V2_SUCCESS = "[Products] EXPORT_PRICES_V2_SUCCESS",
  EXPORT_PRICES_V2_FAIL = "[Products] EXPORT_PRICES_V2_FAIL",

  IMPORT_PRICES = "[Products] IMPORT_PRICES",
  IMPORT_PRICES_SUCCESS = "[Products] IMPORT_PRICES_SUCCESS",
  IMPORT_PRICES_FAIL = "[Products] IMPORT_PRICES_FAIL",

  UPDATE_PRICE = "[Products] UPDATE_PRICE",
  UPDATE_PRICE_SUCCESS = "[Products] UPDATE_PRICE_SUCCESS",
  UPDATE_PRICE_FAIL = "[Products] UPDATE_PRICE_FAIL",

  UPDATE_PROMOTION = "[Products] UPDATE_PROMOTION",
  UPDATE_PROMOTION_SUCCESS = "[Products] UPDATE_PROMOTION_SUCCESS",
  UPDATE_PROMOTION_FAIL = "[Products] UPDATE_PROMOTION_FAIL",

  CREATE_PRICE = "[Products] CREATE_PRICE",
  CREATE_PRICE_SUCCESS = "[Products] CREATE_PRICE_SUCCESS",
  CREATE_PRICE_FAIL = "[Products] CREATE_PRICE_FAIL",

  GET_PRICE_DETAIL = "[Products] GET_PRICE_DETAIL",
  GET_PRICE_DETAIL_SUCCESS = "[Products] GET_PRICE_DETAIL_SUCCESS",
  GET_PRICE_DETAIL_FAIL = "[Products] GET_PRICE_DETAIL_FAIL",

  GET_PRODUCT_SETTINGS = "[Products] GET_PRODUCT_SETTINGS",
  GET_PRODUCT_SETTINGS_SUCCESS = "[Products] GET_PRODUCT_SETTINGS_SUCCESS",
  GET_PRODUCT_SETTINGS_FAIL = "[Products] GET_PRODUCT_SETTINGS_FAIL",

  GET_VARIANT_DETAIL = "[Products] GET_VARIANT_DETAIL",
  GET_VARIANT_DETAIL_SUCCESS = "[Products] GET_VARIANT_DETAIL_SUCCESS",
  GET_VARIANT_DETAIL_FAIL = "[Products] GET_VARIANT_DETAIL_FAIL",
  
  DELETE_PRICE = "[Products] DELETE_PRICE",
  DELETE_PRICE_SUCCESS = "[Products] DELETE_PRICE_SUCCESS",
  DELETE_PRICE_FAIL = "[Products] DELETE_PRICE_FAIL",

  GET_PRICE_CUSTOMER_GROUPS = "[Products] GET_PRICE_CUSTOMER_GROUPS",
  GET_PRICE_CUSTOMER_GROUPS_SUCCESS = "[Products] GET_PRICE_CUSTOMER_GROUPS_SUCCESS",
  GET_PRICE_CUSTOMER_GROUPS_FAIL = "[Products] GET_PRICE_CUSTOMER_GROUPS_FAIL",

  CREATE_CUSTOMER_GROUP_PRICE = "[Products] CREATE_CUSTOMER_GROUP_PRICE",
  CREATE_CUSTOMER_GROUP_PRICE_SUCCESS = "[Products] CREATE_CUSTOMER_GROUP_PRICE_SUCCESS",
  CREATE_CUSTOMER_GROUP_PRICE_FAIL = "[Products] CREATE_CUSTOMER_GROUP_PRICE_FAIL",
  
  SETTING_CUSTOM_PRODUCT = "[Products] SETTING_CUSTOM_PRODUCT",
  SETTING_CUSTOM_PRODUCT_SUCCESS = "[Products] SETTING_CUSTOM_PRODUCT_SUCCESS",
  SETTING_CUSTOM_PRODUCT_FAIL = "[Products] SETTING_CUSTOM_PRODUCT_FAIL",

  DELETE_CUSTOMER_GROUP_PRICE = "[Products] DELETE_CUSTOMER_GROUP_PRICE",
  DELETE_CUSTOMER_GROUP_PRICE_SUCCESS = "[Products] DELETE_CUSTOMER_GROUP_PRICE_SUCCESS",
  DELETE_CUSTOMER_GROUP_PRICE_FAIL = "[Products] DELETE_CUSTOMER_GROUP_PRICE_FAIL",

  UPDATE_CUSTOMER_GROUP_PRICE = "[Products] UPDATE_CUSTOMER_GROUP_PRICE",
  UPDATE_CUSTOMER_GROUP_PRICE_SUCCESS = "[Products] UPDATE_CUSTOMER_GROUP_PRICE_SUCCESS",
  UPDATE_CUSTOMER_GROUP_PRICE_FAIL = "[Products] UPDATE_CUSTOMER_GROUP_PRICE_FAIL",

  DISABLE_CUSTOMER_GROUP_PRICE = "[Products] DISABLE_CUSTOMER_GROUP_PRICE",
  DISABLE_CUSTOMER_GROUP_PRICE_SUCCESS = "[Products] DISABLE_CUSTOMER_GROUP_PRICE_SUCCESS",
  DISABLE_CUSTOMER_GROUP_PRICE_FAIL = "[Products] DISABLE_CUSTOMER_GROUP_PRICE_FAIL",

  SET_ACTIVE_PRICE = "[Products] SET_ACTIVE_PRICE",
  SET_ACTIVE_PRICE_SUCCESS = "[Products] SET_ACTIVE_PRICE_SUCCESS",
  SET_ACTIVE_PRICE_FAIL = "[Products] SET_ACTIVE_PRICE_FAIL",

  SET_DISCONTINUED_PRICE = "[Products] SET_DISCONTINUED_PRICE",
  SET_DISCONTINUED_PRICE_SUCCESS = "[Products] SET_DISCONTINUED_PRICE_SUCCESS",
  SET_DISCONTINUED_PRICE_FAIL = "[Products] SET_DISCONTINUED_PRICE_FAIL",


  GET_ACTIVE_CUSTOMERS = "[Products] GET_ACTIVE_CUSTOMERS",
  GET_ACTIVE_CUSTOMERS_SUCCESS = "[Products] GET_ACTIVE_CUSTOMERS_SUCCESS",
  GET_ACTIVE_CUSTOMERS_FAIL = "[Products] GET_ACTIVE_CUSTOMERS_FAIL",

  GET_OVERRIDE_DISPLAY_PRODUCT = "[Products] GET_OVERRIDE_DISPLAY_PRODUCT",
  GET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS = "[Products] GET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS",
  GET_OVERRIDE_DISPLAY_PRODUCT_FAIL = "[Products] GET_OVERRIDE_DISPLAY_PRODUCT_FAIL",

  SET_OVERRIDE_DISPLAY_PRODUCT = "[Products] SET_OVERRIDE_DISPLAY_PRODUCT",
  SET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS = "[Products] SET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS",
  SET_OVERRIDE_DISPLAY_PRODUCT_FAIL = "[Products] SET_OVERRIDE_DISPLAY_PRODUCT_FAIL",

  SET_PRODUCT_VISIBLE = "[Products] SET_PRODUCT_VISIBLE",
  SET_PRODUCT_VISIBLE_SUCCESS = "[Products] SET_PRODUCT_VISIBLE_SUCCESS",
  SET_PRODUCT_VISIBLE_FAIL = "[Products] SET_PRODUCT_VISIBLE_FAIL",

  SET_PRODUCT_PRIORITY = "[Products] SET_PRODUCT_PRIORITY",
  SET_PRODUCT_PRIORITY_SUCCESS = "[Products] SET_PRODUCT_PRIORITY_SUCCESS",
  SET_PRODUCT_PRIORITY_FAIL = "[Products] SET_PRODUCT_PRIORITY_FAIL",

  GET_IMPORT_PRICES_HISTORY = "[Products] GET_IMPORT_PRICES_HISTORY",
  GET_IMPORT_PRICES_HISTORY_SUCCESS = "[Products] GET_IMPORT_PRICES_HISTORY_SUCCESS",
  GET_IMPORT_PRICES_HISTORY_FAIL = "[Products] GET_IMPORT_PRICES_HISTORY_FAIL",

  EXPORT_VARIANTS = "[Products] EXPORT_VARIANTS",
  EXPORT_VARIANTS_SUCCESS = "[Products] EXPORT_VARIANTS_SUCCESS",
  EXPORT_VARIANTS_FAIL = "[Products] EXPORT_VARIANTS_FAIL",
  
  IMPORT_VARIANTS = "[Products] IMPORT_VARIANTS",
  IMPORT_VARIANTS_SUCCESS = "[Products] IMPORT_VARIANTS_SUCCESS",
  IMPORT_VARIANTS_FAIL = "[Products] IMPORT_VARIANTS_FAIL",

  SET_COLOR_VARIANT = "[Products] SET_COLOR_VARIANT",
  SET_COLOR_VARIANT_SUCCESS = "[Products] SET_COLOR_VARIANT_SUCCESS",
  SET_COLOR_VARIANT_FAIL = "[Products] SET_COLOR_VARIANT_FAIL",

  GET_FILE_AWS = "[Products] GET_FILE_AWS",
  GET_FILE_AWS_SUCCESS = "[Products] GET_FILE_AWS_SUCCESS",
  GET_FILE_AWS_FAIL = "[Products] GET_FILE_AWS_FAIL",

  IMPORT_ALARM_PRODUCTS = "[Products] IMPORT_ALARM_PRODUCTS",
  IMPORT_ALARM_PRODUCTS_SUCCESS = "[Products] IMPORT_ALARM_PRODUCTS_SUCCESS",
  IMPORT_ALARM_PRODUCTS_FAIL = "[Products] IMPORT_ALARM_PRODUCTS_FAIL",

  RESET_ALARM_PRODUCTS = "[Products] RESET_ALARM_PRODUCTS",

  GET_PRODUCTS_UPLOAD_HISTORY = "[Products] GET_PRODUCTS_UPLOAD_HISTORY",
  GET_PRODUCTS_UPLOAD_HISTORY_SUCCESS = "[Products] GET_PRODUCTS_UPLOAD_HISTORY_SUCCESS",
  GET_PRODUCTS_UPLOAD_HISTORY_FAIL = "[Products] GET_PRODUCTS_UPLOAD_HISTORY_FAIL",

  GET_DETAIL_ERROR = "[REPORT] GET_DETAIL_ERROR",
  GET_DETAIL_ERROR_SUCCESS = "[REPORT] GET_DETAIL_ERROR_SUCCESS",
  GET_DETAIL_ERROR_FAIL = "[REPORT] GET_DETAIL_ERROR_FAIL",

  RESET_GET_UPLOAD_PRODUCTS_FILE_STATE = "[Products] RESET_GET_UPLOAD_PRODUCTS_FILE_STATE",

  GET_PRODUCTS_ALARM_HISTORY_FILE = "[Products] GET_PRODUCTS_ALARM_HISTORY_FILE",
  GET_PRODUCTS_ALARM_HISTORY_FILE_SUCCESS = "[Products] GET_PRODUCTS_ALARM_HISTORY_FILE_SUCCESS",
  GET_PRODUCTS_ALARM_HISTORY_FILE_FAIL = "[Products] GET_PRODUCTS_ALARM_HISTORY_FILE_FAIL",

  
}

export const getSellingPrices = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_PRICES,
  payload
});

export const getSellingPricesSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_PRICES_SUCCESS,
  payload,
});

export const getSellingPricesFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_PRICES_FAIL,
  error,
});

export const getSellingPricesWithTopCatalog = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_CATALOG_PRICES,
  payload
});

export const getSellingPricesWithTopCatalogSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_CATALOG_PRICES_SUCCESS,
  payload,
});

export const getSellingPricesWithTopCatalogFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_SELLING_CATALOG_PRICES_FAIL,
  error,
});

export const getDiscontinuedPrices = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.GET_DISCONTINUED_PRICES,
  payload
});

export const getDiscontinuedPricesSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_DISCONTINUED_PRICES_SUCCESS,
  payload,
});

export const getDiscontinuedPricesFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_DISCONTINUED_PRICES_FAIL,
  error,
});

export const searchProducts = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.SEARCH_PRODUCTS,
  payload
});

export const searchProductsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SEARCH_PRODUCTS_SUCCESS,
  payload,
});

export const searchProductsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SEARCH_PRODUCTS_FAIL,
  error,
});

export const getUnSellingPrices = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.GET_UNSELLING_PRICES,
  payload
});

export const getUnSellingPricesSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_UNSELLING_PRICES_SUCCESS,
  payload,
});

export const getUnSellingPricesFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_UNSELLING_PRICES_FAIL,
  error,
});

// Get Paused Product
export const getPausedProducts = (payload: IGetProductsPayload): IAction<any> => ({
  type: ProductActionTypes.GET_PAUSED_PRODUCTS,
  payload
});

export const getPausedProductsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_PAUSED_PRODUCTS_SUCCESS,
  payload,
});

export const getPausedProductsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PAUSED_PRODUCTS_FAIL,
  error,
});

//export prices
export const exportPrices = (payload: IExportPricePayload): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES,
  payload
});

export const exportPricesSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES_SUCCESS,
  payload,
});

export const exportPricesFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES_FAIL,
  error,
});

//export prices v2
export const exportPricesV2 = (payload: IExportPricePayload): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES_V2,
  payload
});

export const exportPricesV2Success = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES_V2_SUCCESS,
  payload,
});

export const exportPricesV2Fail = (error: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_PRICES_V2_FAIL,
  error,
});

//export prices
export const getImportPricesHistory = (payload: IImportPricesHistoryPayload): IAction<any> => ({
  type: ProductActionTypes.GET_IMPORT_PRICES_HISTORY,
  payload
});

export const getImportPricesHistorySuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_IMPORT_PRICES_HISTORY_SUCCESS,
  payload,
});

export const getImportPricesHistoryFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_IMPORT_PRICES_HISTORY_FAIL,
  error,
});

//import prices
export const importPrices = (payload: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_PRICES,
  payload
});

export const importPricesSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_PRICES_SUCCESS,
  payload,
});

export const importPricesFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_PRICES_FAIL,
  error,
});
// Update Price
export const updatePrice = (payload: IUpdatePricePayload): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PRICE,
  payload,
});

export const updatePriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PRICE_SUCCESS,
  payload,
});

export const updatePriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PRICE_FAIL,
  error,
});

// Update Promotion
export const updatePromotion = (payload: IUpdatePromotionPayload): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PROMOTION,
  payload,
});

export const updatePromotionSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PROMOTION_SUCCESS,
  payload,
});

export const updatePromotionFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_PROMOTION_FAIL,
  error,
});

// Create Price
export const createPrice = (payload: ICreatePricePayload): IAction<any> => ({
  type: ProductActionTypes.CREATE_PRICE,
  payload,
});

export const createPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.CREATE_PRICE_SUCCESS,
  payload,
});

export const createPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.CREATE_PRICE_FAIL,
  error,
});


// Get Price Detail
export const getPriceDetail = (payload: IGetPriceDetailPayload): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_DETAIL,
  payload,
});

export const getPriceDetailSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_DETAIL_SUCCESS,
  payload,
});

export const getPriceDetailFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_DETAIL_FAIL,
  error,
});

// get Product Settings
export const getProductSettings = (payload: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCT_SETTINGS,
  payload,
});

export const getProductSettingsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCT_SETTINGS_SUCCESS,
  payload,
});

export const getProductSettingsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCT_SETTINGS_FAIL,
  error,
});

// Get Variant Detail
export const getVariantDetail = (payload: any): IAction<any> => ({
  type: ProductActionTypes.GET_VARIANT_DETAIL,
  payload,
});

export const getVariantDetailSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_VARIANT_DETAIL_SUCCESS,
  payload,
});

export const getVariantDetailFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_VARIANT_DETAIL_FAIL,
  error,
});

// Delete Price
export const deletePrice = (payload: IGetPriceDetailPayload): IAction<any> => ({
  type: ProductActionTypes.DELETE_PRICE,
  payload,
});

export const deletePriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.DELETE_PRICE_SUCCESS,
  payload,
});

export const deletePriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.DELETE_PRICE_FAIL,
  error,
});


// Get Price for customer groups
export const getPriceCustomerGroups = (payload: IGetPriceDetailPayload): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_CUSTOMER_GROUPS,
  payload,
});

export const getPriceCustomerGroupsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_CUSTOMER_GROUPS_SUCCESS,
  payload,
});

export const getPriceCustomerGroupsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRICE_CUSTOMER_GROUPS_FAIL,
  error,
});

// Create Price
export const createCustomerGroupPrice = (payload: ICreateCustomerGroupPricePayload): IAction<any> => ({
  type: ProductActionTypes.CREATE_CUSTOMER_GROUP_PRICE,
  payload,
});

export const createCustomerGroupPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.CREATE_CUSTOMER_GROUP_PRICE_SUCCESS,
  payload,
});

export const createCustomerGroupPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.CREATE_CUSTOMER_GROUP_PRICE_FAIL,
  error,
});

// setting Custom Product
export const settingCustomProduct = (payload: any): IAction<any> => ({
  type: ProductActionTypes.SETTING_CUSTOM_PRODUCT,
  payload,
});

export const settingCustomProductSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SETTING_CUSTOM_PRODUCT_SUCCESS,
  payload,
});

export const settingCustomProductFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SETTING_CUSTOM_PRODUCT_FAIL,
  error,
});

// Delete Group Price
export const deleteCustomerGroupPrice = (payload: any): IAction<any> => ({
  type: ProductActionTypes.DELETE_CUSTOMER_GROUP_PRICE,
  payload,
});

export const deleteCustomerGroupPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.DELETE_CUSTOMER_GROUP_PRICE_SUCCESS,
  payload,
});

export const deleteCustomerGroupPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.DELETE_CUSTOMER_GROUP_PRICE_FAIL,
  error,
});

// Update Group Price
export const updateCustomerGroupPrice = (payload: IUpdateCustomerGroupPricePayload): IAction<any> => ({
  type: ProductActionTypes.UPDATE_CUSTOMER_GROUP_PRICE,
  payload,
});

export const updateCustomerGroupPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_CUSTOMER_GROUP_PRICE_SUCCESS,
  payload,
});

export const updateCustomerGroupPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.UPDATE_CUSTOMER_GROUP_PRICE_FAIL,
  error,
});

// Update Group Price
export const disablePriceForGroup = (payload: any): IAction<any> => ({
  type: ProductActionTypes.DISABLE_CUSTOMER_GROUP_PRICE,
  payload,
});

export const disablePriceForGroupSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.DISABLE_CUSTOMER_GROUP_PRICE_SUCCESS,
  payload,
});

export const disablePriceForGroupFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.DISABLE_CUSTOMER_GROUP_PRICE_FAIL,
  error,
});

// set active Price
export const setActivePrice = (payload: ISetActivePricePayload): IAction<any> => ({
  type: ProductActionTypes.SET_ACTIVE_PRICE,
  payload,
});

export const setActivePriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_ACTIVE_PRICE_SUCCESS,
  payload,
});

export const setActivePriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_ACTIVE_PRICE_FAIL,
  error,
});
// set hide for all customers
export const setDiscontinuedPrice = (payload: any): IAction<any> => ({
  type: ProductActionTypes.SET_DISCONTINUED_PRICE,
  payload,
});

export const setDiscontinuedPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_DISCONTINUED_PRICE_SUCCESS,
  payload,
});

export const setDiscontinuedPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_DISCONTINUED_PRICE_FAIL,
  error,
});

// set active customers
export const getActiveCustomers = (payload: IActiveCustomersPayload): IAction<any> => ({
  type: ProductActionTypes.GET_ACTIVE_CUSTOMERS,
  payload,
});

export const getActiveCustomersSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_ACTIVE_CUSTOMERS_SUCCESS,
  payload,
});

export const getActiveCustomersFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_ACTIVE_CUSTOMERS_FAIL,
  error,
});

// get override display product
export const getOverrideDisplay = (payload: IActiveCustomersPayload): IAction<any> => ({
  type: ProductActionTypes.GET_OVERRIDE_DISPLAY_PRODUCT,
  payload,
});

export const getOverrideDisplaySuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS,
  payload,
});

export const getOverrideDisplayFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_OVERRIDE_DISPLAY_PRODUCT_FAIL,
  error,
});
// set override display product
export const setOverrideDisplay = (payload: ISetOverrideDisplayPayload): IAction<any> => ({
  type: ProductActionTypes.SET_OVERRIDE_DISPLAY_PRODUCT,
  payload,
});

export const setOverrideDisplaySuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_OVERRIDE_DISPLAY_PRODUCT_SUCCESS,
  payload,
});

export const setOverrideDisplayFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_OVERRIDE_DISPLAY_PRODUCT_FAIL,
  error,
});

export const setProductVisible = (payload: ISetProductVisiblePayload): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_VISIBLE,
  payload
});

export const setProductVisibleSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_VISIBLE_SUCCESS,
  payload,
});

export const setProductVisibleFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_VISIBLE_FAIL,
  error,
});

export const setProductPriorityExec = (payload: ISetProductPriorityPayload): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_PRIORITY,
  payload
});

export const setProductPrioritySuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_PRIORITY_SUCCESS,
  payload,
});

export const setProductPriorityFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_PRODUCT_PRIORITY_FAIL,
  error,
});

//export variants
export const getFileImportPrice = (payload: IGetFilePayload): IAction<any> => ({
  type: ProductActionTypes.GET_FILE_AWS,
  payload
});

export const getFileImportPriceSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_FILE_AWS_SUCCESS,
  payload,
});

export const getFileImportPriceFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_FILE_AWS_FAIL,
  error,
});

//get file from cloud
export const exportVariants = (payload: IExportVariantsPayload): IAction<any> => ({
  type: ProductActionTypes.EXPORT_VARIANTS,
  payload
});

export const exportVariantsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_VARIANTS_SUCCESS,
  payload,
});

export const exportVariantsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.EXPORT_VARIANTS_FAIL,
  error,
});

//export variants
export const importVariants = (payload: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_VARIANTS,
  payload
});

export const importVariantsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_VARIANTS_SUCCESS,
  payload,
});

export const importVariantsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_VARIANTS_FAIL,
  error,
});

export const setColorVariant = (payload: ISetColorVariantPayload): IAction<any> => ({
  type: ProductActionTypes.SET_COLOR_VARIANT,
  payload
});

export const setColorVariantSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.SET_COLOR_VARIANT_SUCCESS,
  payload,
});

export const setColorVariantFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.SET_COLOR_VARIANT_FAIL,
  error,
});


export const importAlarmProducts = (payload: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_ALARM_PRODUCTS,
  payload,
});

export const importAlarmProductsSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_ALARM_PRODUCTS_SUCCESS,
  payload,
});

export const importAlarmProductsFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.IMPORT_ALARM_PRODUCTS_FAIL,
  error,
});

export const resetAlarmProducts = (): IAction<any> => ({
  type: ProductActionTypes.RESET_ALARM_PRODUCTS,
});


export const getDetailError = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_DETAIL_ERROR,
  payload,
});

export const getDetailErrorSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_DETAIL_ERROR_SUCCESS,
  payload,
});

export const getDetailErrorFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_DETAIL_ERROR_FAIL,
  error,
});

export const getProductsUploadHistory = (payload?: IGetProductsUploadHistoryPayload): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_UPLOAD_HISTORY,
  payload,
});

export const getProductsUploadHistorySuccess = (payload?: IGetProductsUploadHistoryPayload): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_UPLOAD_HISTORY_SUCCESS,
  payload,
});

export const getProductsUploadHistoryFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_UPLOAD_HISTORY_FAIL,
  error,
});

export const resetGetUploadProductsFileState = (): IAction<any> => ({
  type: ProductActionTypes.RESET_GET_UPLOAD_PRODUCTS_FILE_STATE,
});

export const getAlarmUploadFile = (payload: string): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE,
  payload,
});

export const getAlarmUploadFileSuccess = (payload?: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE_SUCCESS,
  payload,
});

export const getAlarmUploadFileFail = (error: any): IAction<any> => ({
  type: ProductActionTypes.GET_PRODUCTS_ALARM_HISTORY_FILE_FAIL,
  error,
});