import { IActiveCustomersPayload,ISetActivePricePayload, ICreateCustomerGroupPricePayload, ICreatePricePayload, IGetPriceDetailPayload, IUpdateCustomerGroupPricePayload, IUpdatePricePayload, IImportPricesHistoryPayload, IExportVariantsPayload, ISetColorVariantPayload, IGetFilePayload } from '../../../presentation/redux/actions/interfaces/product.types';
import { IExportPricePayload, IGetProductsPayload, ISetOverrideDisplayPayload, ISetProductPriorityPayload, ISetProductVisiblePayload } from '../../../presentation/redux/actions/products/products.types';
import ProductCloud from './cloud';
import ProductStorage from './storage';

export default class ProductRepository {
    public getSellingPrices = (payload: IGetProductsPayload) => {
        return ProductCloud.getSellingPrices(payload);
    };
    public getSellingPricesWithTopCatalog = (payload: IGetProductsPayload) => {
        return ProductCloud.getSellingPricesWithTopCatalog(payload);
    };
    public getDiscontinuedPrices = (payload: IGetProductsPayload) => {
        return ProductCloud.getDiscontinuedPrices(payload);
    };

    public searchProducts = (payload: IGetProductsPayload) => {
        return ProductCloud.searchProducts(payload);
    };

    public getUnSellingPrices = (payload: IGetProductsPayload) => {
        return ProductCloud.getUnSellingPrices(payload);
    };

    public getGetPausedProducts = (payload: IGetProductsPayload) => {
        return ProductCloud.getPausedProducts(payload);
    };
    
    public updatePrice = (payload: IUpdatePricePayload) => {
        return ProductCloud.updatePrice(payload);
    };

    public setActivePrice = (payload: ISetActivePricePayload) => {
        return ProductCloud.setActivePrice(payload);
    };

    public setDiscontinuedPrice = (payload: any) => {
        return ProductCloud.setDiscontinuedPrice(payload);
    };

    public getActiveCustomers = (payload: IActiveCustomersPayload) => {
        return ProductCloud.getActiveCustomers(payload);
    };

    public getOverrideDisplay = (payload: IActiveCustomersPayload) => {
        return ProductCloud.getOverrideDisplay(payload);
    };

    public updatePromotion = (payload: any) => {
        return ProductCloud.updatePromotion(payload);
    };

    public createPrice = (payload: ICreatePricePayload) => {
        return ProductCloud.createPrice(payload);
    };

    public getPriceDetailRepository = (id: string) => {
        return ProductCloud.getPriceDetail(id);
    };

    public getDetailErrorRepository = (payload: any) => {
        return ProductCloud.getDetailError(payload);
	};
	
    public getProductSettingsRepository = (pauload: any) => {
        return ProductCloud.getProductSettings(pauload);
    };

    public getVariantDetailRepository = (id: string) => {
        return ProductCloud.getVariantDetail(id);
    };

    public deletePriceDetailRepository = (payload: IGetPriceDetailPayload) => {
        return ProductCloud.deletePrice(payload);
    };

    public getPriceCustomerGroupsRepository = (id: string) => {
        return ProductCloud.getPriceCustomerGroups(id);
    };

    public createCustomerGroupPriceRepository = (payload: ICreateCustomerGroupPricePayload) => {
        return ProductCloud.createCustomerGroupPrice(payload);
    };
    
    public settingCustomProductRepository = (payload: any) => {
        return ProductCloud.settingCustomProduct(payload);
    };

    public deleteCustomerGroupPriceRepository = (payload: any) => {
        return ProductCloud.deleteCustomerGroupPrice(payload);
    };

    public updateCustomerGroupPriceRepository = (payload: IUpdateCustomerGroupPricePayload) => {
        return ProductCloud.updateCustomerGroupPrice(payload);
    };

    public disablePriceForGroupRepository = (payload: any) => {
        return ProductCloud.disablePriceForGroup(payload);
    };

    public exportPricesRepository = (payload: IExportPricePayload) => {
        return ProductCloud.exportPrices(payload);
    };

    public exportPricesV2Repository = (payload: IExportPricePayload) => {
        return ProductCloud.exportPricesV2(payload);
    };

    public exportVariantsRepository = (payload: IExportVariantsPayload) => {
        return ProductCloud.exportVariants(payload);
    };

    public getFileImportPrice = (payload: IGetFilePayload) => {
        return ProductCloud.getFileImportPrice(payload);
    };

    public importVariantsRepository = (payload: any) => {
        return ProductCloud.importVariants(payload);
    };
    
    public importPricesHistoryRepository = (payload: IImportPricesHistoryPayload) => {
        return ProductCloud.importPricesHistory(payload);
    };
    
    public importPricesRepository = (payload: any) => {
        return ProductCloud.importPrices(payload);
    };

    public setProductVisible = (payload: ISetProductVisiblePayload) => {
        return ProductCloud.setProductVisible(payload);
    };

    public setOverrideDisplay = (payload: ISetOverrideDisplayPayload) => {
        return ProductCloud.setOverrideDisplay(payload);
    };

    public setProductPriority = (payload: ISetProductPriorityPayload) => {
        return ProductCloud.setProductPriority(payload);
    };

    public setColorVariant = (payload: ISetColorVariantPayload) => {
        return ProductCloud.setColorVariant(payload);
    };

    public importAlarmProducts = (payload: any) => {
        return ProductCloud.importAlarmProducts(payload);
    };

    public getProductsUploadHistory = (payload: any) => {
        return ProductCloud.getProductsUploadHistory(payload);
      };

    public getAlarmProductsFile = (payload: string) => {
        return ProductCloud.getAlarmProductsUploadFile(payload);
    };
}
