import { combineEpics } from "redux-observable";
import { getDraftCartsEpic } from "./getDraftCartsEpic";
import { clearCartEpic } from "./clearCartEpic";
import { getCartEpic } from "./getCartEpic";
import { addNewCartEpic } from "./addNewCartEpic";
import { createOrderOnBehalfEpic } from "./createOrderOnBehalfEpic";
import { saveCartEpic } from "./saveCartEpic";
import { createOnBehalfOrder } from "../../actions/orderonbehalf/orderonbehalf.actions";

export const orderOnBehalfEpic = combineEpics(
  getDraftCartsEpic,
  clearCartEpic,
  getCartEpic,
  addNewCartEpic,
  saveCartEpic,
  createOrderOnBehalfEpic
);
