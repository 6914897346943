import AttachFile from "@mui/icons-material/AttachFile";
import { v4 } from "uuid";
import { PriceCustomerGroupType, PricesResponse, PriceType } from "../../models/response/product";

export default class ApiHelper {
    static getPrices(products: PricesResponse){
        if(products.prices.length > 0){

            const newProducts: any[] = [];
            products.prices.forEach((item: any) => {
                item.variants.forEach((data: any) => {
                    data.prices.forEach((price: PriceType) => {
                        newProducts.push(
                            {
                                ...price,
                                productId: item.id,
                                productName: item.name,
                                variantName: data.name, 
                                variantId: data.id,
                                image: data.image,
                                size: data.size,
                                type: data.type,
                                packageType: data.packageType,
                                quantityPerPackage: data.quantityPerPackage,
                                priority: item.priority,
                                variantPriority: data.priority,
                                isCatalog:item.isMyCatalog
                            }
                        )
                    }) 
                })
            });
        return {...products, prices: newProducts};

        } else {
            return products;
        }
        
    }

    static getUnsellingPrices(products: PricesResponse){
        if(products.prices.length > 0){

            const newProducts: any[] = [];
            products.prices.forEach((item: any) => {
                item.variants.forEach((data: any) => {
                    newProducts.push(
                        {
                            ...data,
                            productId: item.id,
                            productName: item.name,
                            variantName: data.name, 
                            variantId: data.id,
                            image: data.image,
                            size: data.size,
                            type: data.type,
                            packageType: data.packageType,
                            quantityPerPackage: data.quantityPerPackage,
                        }
                    )
                })
            });
        return {...products, prices: newProducts};

        } else {
            return products;
        }
    }

    static getPriceCustomerGroups(products: PriceCustomerGroupType[]){
        const result = products.map((item: any) => {
            return item.draft;
        })
        return result;
    }

    static exportExcelFile = (baseData: any, fileName?: string) => {
        let fileExportName = fileName ?? 'file-export';
        const bin = window.atob(baseData);
			const ab = s2ab(bin);
			const blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
			const csvURL = window.URL.createObjectURL(blob);
			const tempLink = document.createElement('a');
			tempLink.href = csvURL;
			tempLink.setAttribute('download', `${fileExportName}-${v4()}.xlsx`);
			tempLink.click();
    }

    static getVariants(products: PricesResponse){
        if(products.prices.length > 0){

            const newProducts: any[] = [];
            products.prices.forEach((item: any) => {
                item.variants.forEach((data: any) => {
                    newProducts.push(
                        {
                            productId: item.id,
                            productName: item.name,
                            variantName: data.name, 
                            variantId: data.id,
                            image: data.image,
                            size: data.size,
                            type: data.type,
                            packageType: data.packageType,
                            quantityPerPackage: data.quantityPerPackage,
                            priority: item.priority,
                            variantPriority: data.priority
                        }
                    )
                })
            });
        return {...products, prices: newProducts};

        } else {
            return products;
        }
        
    }
}

const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}